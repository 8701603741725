import request from '@/utils/request.js';
const BASEURL = process.env.VUE_APP_BASE_URL;

export function CASESLIST(params) {
  return request({
    url: BASEURL + '/home/web/awards',
    method: 'GET',
    params,
  });
}

export function GETOPTIONS(params) {
  return request({
    url: BASEURL + '/home/web/awards_option',
    method: 'GET',
    params,
  });
}

export function CASEDETAIL(params) {
  return request({
    url: BASEURL + '/home/web/awards_detail',
    method: 'GET',
    params,
  });
}